<template>
    <button class="dropdown-item" @click="open()"> 
            <i class="bi bi-geo-alt me-2"></i>
        {{ $t('Set Location')}}
    </button>
    <LeafletModal v-if="loadModal" :config="geoLocationConfig" :item = "row" ref="leafmodal"  @hidden="handleHidden"/>
</template>

<script setup>

import {ref} from 'vue';
import LeafletModal from 'system.vue.components.LeafletModal.vue';
const props = defineProps(['row','geoLocationConfig'])
const leafmodal = ref(null);
const loadModal = ref(false);
const open = () =>{
    loadModal.value = true;
    window.setTimeout(()=>{
        leafmodal.value.modal.show();

    },50)
}
const handleHidden = () =>{
    loadModal.value = false;
}

</script>